<template>
  <div v-if="evaluationData">
    <form-wizard
      color="#7367F0"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="حفظ"
      back-button-text="سابق"
      next-button-text="تالي"
      class="mb-3"
    >
      <!-- "موقع العقار" tab -->
      <tab-content
        title="موقع العقار"
        :before-change="validateLocation"
      >
        <property-location :evaluation="evaluationData" />
      </tab-content>

      <!-- "معلومات عامة" tab -->
      <tab-content
        title="معلومات عامة"
        :before-change="validateOther"
      >
        <property-other-info :evaluation="evaluationData" />
      </tab-content>

      <!-- "معلومات العقار" tab -->
      <tab-content
        title="معلومات العقار"
        :before-change="validateProperty"
      >
        <property-info :evaluation="evaluationData" />
      </tab-content>

      <!-- "مواصفات العقار" tab  -->
      <tab-content
        title="مواصفات العقار"
        :before-change="validateSpecifications"
      >
        <property-specifications :evaluation="evaluationData" />
      </tab-content>

      <!-- "تقييم العقار" tab -->
      <tab-content
        title="تقييم العقار"
        :before-change="validateEvaluation"
      >
        <property-evaluation :evaluation="evaluationData" />
      </tab-content>

      <!-- social link -->
      <tab-content
        title="المرفقات"
      >
        <attachments :evaluation="evaluationData" />
      </tab-content>
      <template
        slot="finish"
        slot-scope="props"
      >
        <wizard-button
          :disabled="props.loading"
          class="wizard-footer-right finish-button"
          :style="props.fillButtonStyle"
          @click.native="formSubmitted()"
        >
          {{ $t('save') }}
        </wizard-button>
        <wizard-button
          v-if="$can('review', 'evaluation') && canReview()"
          :disabled="props.loading"
          class="wizard-footer-right finish-button mr-1"
          :style="props.fillButtonStyle"
          @click.native="formSubmitted('submitForApprovalEvaluation')"
        >
          تحويل المعاملة للاعتماد
        </wizard-button>
        <wizard-button
          v-if="canSubmitForApproval()"
          :disabled="props.loading"
          class="wizard-footer-right finish-button mr-1"
          :style="props.fillButtonStyle"
          @click.native="formSubmitted('aproveEvaluation')"
        >
          إعتماد التقييم
        </wizard-button>
      </template>
    </form-wizard>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard';
import evaluationStore from '../evaluationStore';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import PropertyLocation from './property-location/PropertyLocation';
import PropertyInfo from './property-info/PropertyInfo';
import PropertySpecifications from './property-specifications/PropertySpecifications';
import PropertyEvaluation from './property-evaluation/PropertyEvaluation.vue';
import PropertyOtherInfo from './property-other-info/PropertyOtherInfo.vue';
import { evaluationMixin } from '@/@core/mixins/modules/evaluation';

import Attachments from './attachments/Attachments.vue';

export default {
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    PropertyLocation,
    PropertyInfo,
    PropertySpecifications,
    PropertyEvaluation,
    PropertyOtherInfo,
    Attachments,
  },
  mixins: [alertMessageMixin, evaluationMixin],
  data() {
    return {
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  methods: {
    async formSubmitted(action = 'updateEvaluation') {
      await this.validationAttachmentsForm();
      this.updateEvaluation(action);
    },
    updateEvaluation(currentAction) {
      this.$store
        .dispatch(`evaluation/${currentAction}`, { evaluationData: this.evaluationData, id: this.evaluationData.id })
        .then((response) => {
          this.$toast.success(response.data.message);
          router.push(`/evaluation-request/${this.evaluationData.evaluation_request_id}/view`).catch(() => { });
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              const { message, errors } = err.response.data;
              if (errors) {
                const errorMessage = {
                  message: '',
                  type: 'danger',
                };
                Object.values(errors).forEach((error) => {
                  errorMessage.message += `${error[0]}<br>`;
                });
                this.displayMessage(errorMessage);
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  text: message,
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                },
              });
            } else if (err.response.data.message) {
              this.$toast.error(err.response.data.message);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              this.$refs.refFormObserver.setErrors(err.response.data.errors);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const EVALUATION_STORE_MODULE_NAME = 'evaluation';

    // Register evaluation
    if (!store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.registerModule(EVALUATION_STORE_MODULE_NAME, evaluationStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATION_STORE_MODULE_NAME)) store.unregisterModule(EVALUATION_STORE_MODULE_NAME);
    });
    const evaluationData = ref(null);
    let blankEvaluationData = {};
    store
      .dispatch('evaluation/fetchEditEvaluation', {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        const { data: evaluation } = response.data;
        evaluationData.value = evaluation;
        blankEvaluationData = evaluation;
      })
      .catch((error) => {
        console.log(error);
        if (error.response && error.response.status === 404) {
          evaluationData.value = undefined;
        }
      });
    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(JSON.stringify(blankEvaluationData));
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationData);
    return {
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
