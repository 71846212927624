<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="propertyInfoRules"
  >
    <b-form
      v-if="evaluationData"
      class="mt-1"
      @submit.prevent="handleSubmit(addEvaluation)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert

            variant="info"
            :show="features.length == 0"
            class="mb-0"
          >
            <div class="alert-body">
              <feather-icon
                icon="InfoIcon"
                class="mr-50"
              />
              يمكنك المرور الى المرحلة التالية.
              <br>
              لا يوجد حقول للتعمير للعقار من فئة  <strong>{{ type.name }}</strong>
            </div>
          </b-alert>
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row
        v-for="(group, index) in features"
        :id="group.id"
        :key="index"
      >
        <b-col
          cols="12"
          md="12"
          lg="12"
        >
          <div class="d-flex">
            <feather-icon
              icon="GlobeIcon"
              size="19"
            />
            <h4 class="mb-0 ml-50">
              {{ group.display_name }}
            </h4>
          </div>
        </b-col>
        <b-col cols="12">
          <hr class="my-2">
        </b-col>
        <b-col
          v-for="(feature, j) in group.features"
          :id="feature.id"
          :key="j"
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: feature.name -->
          <validation-provider
            v-if="checkCondition(feature.feature)"
            #default="validationContext"
            :vid="feature.feature.name"
            :name="feature.feature.name"
          >
            <b-form-group
              v-if="feature.feature.name !== 'custom_secondary_valuation_method' || feature.feature.disabled"
              :label="feature.feature.display_name"
              :label-for="feature.feature.name"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-if="feature.feature.value_type === 'enum'"
                v-model="
                  evaluationData.features[calculateFeatureIndex(feature)].value
                "
                :close-on-select="!feature.feature.value_options.multiple"
                :multiple="feature.feature.value_options.multiple"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="feature.feature.value_options.options"
                :clearable="true"
                
                @input="featureChanged(feature, index, $event)"
              />
              <b-form-radio-group
                v-else-if="feature.feature.value_type === 'boolean'"
                v-model="
                  evaluationData.features[calculateFeatureIndex(feature)].value
                "
                :options="yesNoOptions"
                class="demo-inline-spacing"
                :name="feature.feature.name"
              />
              <b-form-textarea
                v-else-if="feature.feature.value_type === 'text'"
                :id="feature.feature.name"
                v-model="
                  evaluationData.features[calculateFeatureIndex(feature)].value
                "
              />
              <b-form-input
                v-else
                :id="feature.feature.name"
                v-model="
                  evaluationData.features[calculateFeatureIndex(feature)].value
                "
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import {
  getCurrentFeatures,
  evaluationMixin,
} from '@/@core/mixins/modules/evaluation';
import { propertyOtherInfoMixin } from '@/@core/mixins/modules/evaluation/propertyOtherInfo';
import evaluationStore from '../../evaluationStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin, evaluationMixin, propertyOtherInfoMixin],
  props: {
    evaluation: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isLoading: false,
      required,
      alphaNum,
      validEmail,
      yesNoOptions: [
        { text: 'نعم', value: true },
        { text: 'لا', value: false },
      ],
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    this.$root.$on('validateOther', async (data) => {
      console.log('validateOther');
      this.$root.$emit('otherValidated', {
        success: true,
        data: this.evaluationData,
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateProperty');
  },
  methods: {
    validateRules(feature) {
      console.log(feature);
      return true;
    },
  },
  setup(props) {
    const { evaluation } = props;
    const { property } = evaluation;
    const { type } = property;

    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluation';
    const evaluationData = ref({});
    const blankEvaluationData = ref({});
    // Register evaluation
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });

    const features = ref([]);
    // Retrieve related features
    if (type) {
      store
        .dispatch('evaluation/fetchFeatures', { id: type.id })
        .then((response) => {
          const { data } = response.data;

          features.value = data.groups
            .filter((group) => ['property_other_info', 'valuation_method_info', 'authority_requirements'].includes(
              group.name,
            ))
            .map((group) => {
              group.features = group.features.map((feature) => {
                if (
                  feature.value_type == 'enum'
                  && validateRules(feature.feature)
                ) {
                  feature.value_options.options = feature.value_options.options.map((option) => ({
                    value: option,
                    label: option,
                  }));
                }
                return feature;
              });
              return group;
            });

          evaluationData.value = blankEvaluationData.value = {
            features: getCurrentFeatures(features.value, evaluation.features, evaluation),
          };
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            evaluationData.value = undefined;
          }
        });
    }

    const resetevaluationData = () => {
      evaluationData.value = JSON.parse(
        JSON.stringify(blankEvaluationData.value),
      );
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(resetevaluationData);

    return {
      type,
      features,
      evaluationData,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
